<template>
    <div v-if="event">
        <h1>{{ event.title }}</h1>
        <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
        <h4>{{ event.title }}</h4>
    </div>
</template>

<script>
import EventService from "@/services/EventService.js";

export default {
    props: {
        id: String,
    },
    data() {
        return {
            event: null,
        };
    },
    created() {
        console.log(this.id);
        EventService.getEvent(this.id)
            .then((response) => {
                this.event = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<style></style>
